import React, { useRef } from 'react'
import makeStyles from "@mui/styles/makeStyles";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import squarebox from '../../Assests/pseudo.png'
import contactform from '../../Assests/contact-bg.png';
import { useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';


const root = document.querySelector(":root");
const primary = getComputedStyle(root).getPropertyValue('--primaryColor');

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${contactform})`,
    backgroundSize: 'cover',
    padding: '70px 100px 90px 140px',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${contactform})`,
      width: '100%',
      padding: '50px 30px 30px 30px'
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage: `url(${contactform})`,
      width: '100%',
      padding: '50px 30px 30px 30px'
    },
  },
  astesrik: {
    color: primary
  },

  MobPrimaryButtom: {
    border: `2px solid ${primary}`,
    borderRadius: '100px',
    padding: '8px 35px',
    backgroundColor: 'transparent',
    color: primary,
  }

}));

function Index() {
  const classes = useStyles();
  const themes = useTheme();
  const isMobileScreen = useMediaQuery(themes.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(themes.breakpoints.down('md'))

  return (
    <div className={classes.container} id="contactus">
      {isMobileScreen ?
        <>
          <form action="https://submit-form.com/sAhS3WwE">
            <div style={{ width: '100%' }}>
              <div className='font-white-52-bold pb-4'>Contact Us</div>
              <div className='font-white-14-normal pb-2 d-flex'><div>Your Name</div><div className={classes.astesrik}>*</div></div>
              <Box component={'input'} type='text' required height={'43px'} width={'100%'} id="name" name="name" className='font-white-14-normal form-control custom-input px-3' placeholder='Enter your name' sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
              <br />
              <div className='font-white-14-normal pb-2 d-flex'><div>Your Email</div><div className={classes.astesrik}>*</div></div>
              <Box component={'input'} type='email' required height={'43px'} id="email" name="email" className='font-white-14-normal form-control custom-input px-3' placeholder='Enter your email' sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
              <br />
              <div className='font-white-14-normal pb-2 d-flex'><div>Your Phone Number</div><div className={classes.astesrik}>*</div></div>
              <Box component={'input'} type='number' required height={'43px'} id="phone_No" name="phone_No" className='font-white-14-normal form-control custom-input px-3' placeholder='Enter your phone number' sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
              <br />
              <div className='font-white-14-normal pb-2 d-flex'><div>Your Organization</div><div className={classes.astesrik}>*</div></div>
              <Box component={'input'} type='text' required height={'43px'} id="organization_Name" name="organization_Name" className='font-white-14-normal form-control custom-input px-3' placeholder='Enter your organization’s name' sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
              <div className='pt-5 pb-4'>
                <button className={`${classes.MobPrimaryButtom} font-primary-13-bold`} type='submit'>Submit</button>
              </div>
              <div className='font-white-24-thin pb-2 pt-5 '>Our Presence</div>
              <div className='font-white-16-normal pb-5'>INDIA | USA | MEA | APAC</div>
              <Grid container spacing={5}>
                <Grid item><div className='font-primary-14-normal pb-5'>info@thekenverse.com</div></Grid>
                <Grid item><div className='font-white-14-normal'>© Kenverse 2023</div></Grid>
              </Grid>
            </div>
          </form>
        </> :
        <div>
          <form action="https://submit-form.com/sAhS3WwE">
            <h6 className='font-white-122-bold pb-4 pt-3'>Contact Us</h6>
            <Grid container className='pt-3'>
              <Grid item lg={4} md={4} sm={6} xl={4}>
                <div className='font-white-30-normal pb-4'>Our Presence</div>
                <div className='font-white-20-normal'>INDIA | USA | MEA | APAC</div>
                <div className='font-primary-14-normal pb-5'>info@thekenverse.com</div>
                <div className='d-flex align-items-center gap-3 pt-4'>
                  <img src={squarebox} alt='squarebox' />
                  <div className='font-white-14-normal'>© Kenverse 2023</div>
                </div>
              </Grid>
              <Grid item lg={8} md={8} sm={6} xl={8} >
                <div className='font-white-30-normal pb-4'>Get in touch</div>
                <Grid container spacing={5}>
                  <Grid item lg={6} md={6} sm={12}>
                    <div className='font-white-14-normal pb-2 d-flex'><div>Your Name</div><div className={classes.astesrik}>*</div></div>
                    <Box component={'input'} type='text' height={'43px'} id="name" name="name" className='font-white-14-normal form-control custom-input px-3 w-100' placeholder='Enter your name'
                      required sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
                    <br />
                    <div className='font-white-14-normal pb-2 d-flex'><div>Your Phone Number</div><div className={classes.astesrik}>*</div></div>
                    <Box component={'input'} type='number' height={'43px'} id="phone_No" name="phone_No" className='font-white-14-normal form-control custom-input px-3 w-100' placeholder='Enter your phone number'
                      required sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} >
                    <div className='font-white-14-normal pb-2 d-flex'><div>Your Email</div><div className={classes.astesrik}>*</div></div>
                    <Box component={'input'} type='email' height={'43px'} id="email" name="email" className='font-white-14-normal form-control custom-input px-3 w-100' placeholder='Enter your email'
                      required sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
                    <br />
                    <div className='font-white-14-normal pb-2 d-flex'><div>Your Organization</div><div className={classes.astesrik}>*</div></div>
                    <Box component={'input'} type='text' height={'43px'} id="organization_Name" name="organization_Name" className='font-white-14-normal form-control custom-input px-3 w-100' placeholder='Enter your organization’s name'
                      required sx={{ outline: 'none', '&:focus': { boxShadow: 'none', }, '::placeholder': { opacity: 0.5, }, }} />
                  </Grid>
                </Grid>
                <div className='pt-5 font-primary-16-normal w-50'>
                  <button className={isMediumScreen ? 'primary-button font-primary-16-bold w-100' : 'primary-button font-primary-16-bold w-50'} type='submit'>Submit</button>
                </div>
              </Grid>
            </Grid>
            <div />
          </form>
        </div >
      }

    </div >


  )
}

export default Index