import React, { useState, useEffect } from 'react';
import Header from './component/Header/index';
import Second from './component/Second/index';
import Footer from './component/Footer/index';
import logo from './Assests/header/Logo.png'
import { Box, Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ListItem from "@mui/material/ListItem";
import { useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import { Link } from 'react-scroll';
import { AiOutlineRight } from 'react-icons/ai';
import MenuLogo from './Assests/header/MenuLogo.svg';
import MDots from './Assests/header/MDots.svg';
import Modal from '@mui/material/Modal';
import HeaderLogoM from './Assests/header/Logo.png'
import LogoM from './Assests/header/LogoM.svg'
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: '#FFFFFF',
  borderRadius: '0px 0px 15px 15px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  p: 4,
};

const drawerWidth = 125;

const useStyles = makeStyles((theme) => ({
}))

function Website() {
  const themes = useTheme();
  const isMobileScreen = useMediaQuery(themes.breakpoints.down('sm'));
  const classes = useStyles();
  const [scroll, setScroll] = useState("#brands")
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const handleOpen = () => { setOpen(true); }
  const handleClose = () => setOpen(false);
  const Navigation = (data) => {
    window.location.href = data;
    setScroll(data)
  }

  const [activeSection, setActiveSection] = useState('#brands');

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const brandsSection = document.getElementById('brands').offsetTop;
    const aboutUsSection = document.getElementById('aboutus').offsetTop;
    const careersSection = document.getElementById('careers').offsetTop;
    const contactUsSection = document.getElementById('contactus').offsetTop;

    if (scrollPosition < brandsSection) {
      setActiveSection('');
    } else if (scrollPosition >= brandsSection && scrollPosition < aboutUsSection) {
      setActiveSection('brands');
    } else if (scrollPosition >= aboutUsSection && scrollPosition < careersSection) {
      setActiveSection('aboutus');
    } else if (scrollPosition >= careersSection && scrollPosition < contactUsSection) {
      setActiveSection('careers');
    } else if (scrollPosition >= contactUsSection) {
      setActiveSection('contactus');
    }
    if (scrollPosition + window.innerHeight >= document.documentElement.scrollHeight) {
      setActiveSection('contactus');
    }
  };

  React.useEffect(() => {
    handleScroll(); // Call handleScroll initially to set the active section on page load
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Box display={isMobileScreen ? null : 'flex'}>
        {isMobileScreen ? (
          <>
            <Box position={'relative'} >
              <Box position={'absolute'} right={'0'}>
                <img src={MDots} />
              </Box>
            </Box>
            <div className='d-flex justify-content-between'>
              <div className='mt-4 mx-4'   >
                <img src={LogoM} alt={'Logo'} />
              </div>
              <div >
                <div style={{ zIndex: '9', position: 'fixed', right: 0 }} onClick={handleOpen}>
                  <img src={MenuLogo} alt={'menuLogo'} />
                </div>
              </div>
            </div>
            <Modal className='m-4'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <>
                  <div className='d-flex justify-content-between' onClick={() => { handleClose(); Navigation('#brands') }}>
                    <div className='hstack gap-3' >
                      <div className={scroll == '#brands' ? 'squarebox' : 'squareboxNonClick'}></div>
                      <div>Brands</div>
                    </div>
                    <div><AiOutlineRight /></div>
                  </div>
                  <div className='d-flex justify-content-between mt-4' onClick={() => { handleClose(); Navigation('#aboutus') }}>
                    <div className='hstack gap-3'>
                      <div className={scroll == '#aboutus' ? 'squarebox' : 'squareboxNonClick'}></div>
                      <div>About</div>
                    </div>
                    <div ><AiOutlineRight /></div>
                  </div>
                  <div className='d-flex justify-content-between mt-4' onClick={() => { handleClose(); Navigation('#careers') }} >
                    <div className='hstack gap-3'>
                      <div className={scroll == '#careers' ? 'squarebox' : 'squareboxNonClick'}></div>
                      <div>Careers</div>
                    </div>
                    <div><AiOutlineRight /></div>
                  </div>
                  <div className='d-flex justify-content-between mt-4' onClick={() => { handleClose(); Navigation('#contactus') }} >
                    <div className='hstack gap-3'>
                      <div className={window.location == '#contactus' ? 'squarebox' : 'squareboxNonClick'}></div>
                      <div>Contact</div>
                    </div>
                    <div ><AiOutlineRight /></div>
                  </div>
                </>
              </Box>
            </Modal>
          </>
        ) : (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
            }}
            variant="permanent"
            anchor="left">
            <Box className='logo-align m-4'>
              <img src={logo} className='mt-2' width={'70px'} />
            </Box>
            <div className="NavBar-contents">
              <List>
                <ListItem className='mt-4 d-flex align-items-center gap-3' sx={{ transform: "rotate(270deg)", cursor: "pointer" }}>
                  <div className={activeSection === 'brands' ? 'squarebox' : 'squareboxNonClick'}></div>
                  <Box className="nav-link active font-black-16-normal" >
                    <Link
                      to='brands'
                      smooth={true}
                      duration={100}
                      offset={-50}
                      spy={true}
                    >
                      <div className="font-black-18-normal" onClick={() => navigate('/home')}>Brands</div>
                    </Link>
                  </Box>
                </ListItem>
              </List> <br />
              <List className='mt-4'>
                <ListItem className='mt-4 d-flex align-items-center gap-3' sx={{ transform: "rotate(270deg)", cursor: "pointer" }}>
                  <div className={activeSection === 'aboutus' ? 'squarebox' : 'squareboxNonClick'}></div>
                  <Box className="nav-link active font-black-16-normal"  >
                    <Link
                      to='aboutus'
                      smooth={true}
                      duration={100}
                      spy={true}
                      onClick={(() => setActiveSection(true))}
                    >
                      <div className="font-black-18-normal">About Us</div>
                    </Link>
                  </Box>
                </ListItem>
              </List> <br />
              <List className='mt-1'>
                <ListItem className='mt-4 d-flex align-items-center gap-3' sx={{ transform: "rotate(270deg)", cursor: "pointer" }}>
                  <div className={activeSection === 'careers' ? 'squarebox' : 'squareboxNonClick'}></div>
                  <Box className="nav-link active font-black-16-normal" >
                    <Link
                      to='careers'
                      smooth={true}
                      duration={100}
                      onClick={(() => setActiveSection(true))}
                      spy={true}>
                      <div className="font-black-18-normal">Careers</div>
                    </Link>
                  </Box>
                </ListItem>
              </List> <br /> <br />
              <List className='mt-2'>
                <ListItem className='mt-4 d-flex align-items-center gap-3' sx={{ transform: "rotate(270deg)", cursor: "pointer" }}>
                  <div className={activeSection === 'contactus' ? 'squarebox' : 'squareboxNonClick'}></div>
                  <Box className="nav-link active font-black-16-normal">
                    <Link
                      to='contactus'
                      smooth={true}
                      duration={100}
                      onClick={(() => setActiveSection(true))}
                      spy={true}>
                      <div className="font-black-18-normal">Contact Us</div>
                    </Link>
                  </Box>
                </ListItem>
              </List>
            </div>
          </Drawer>
        )
        }
        <Box sx={{ overflow: 'hidden' }}>
          <Header />
          <div >
            <Second />
          </div>
          <div>
            <Footer />
          </div>
        </Box>
      </Box>
    </>
  )
}

export default Website;