import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import Circle from '../../Assests/header/Circle.svg'
import { useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import bgDots from '../../Assests/header/bgDots1.png'

const useStyles = makeStyles((theme) => ({

}))

function Index() {

    const themes = useTheme();
    const isMobileScreen = useMediaQuery(themes.breakpoints.down('sm'));
    const classes = useStyles();
    return (
        <>
            {isMobileScreen ?
                <div className=' m-4' id='brands' >
                    <div className='Title1M'>Into the</div>
                    <div className='Title2M'>Kenverse</div>
                    <div className='BDivivder'>
                        <div className='VDivider'></div>
                    </div>
                    <div className='SubText font-black-18-normal'>Building a universe of knowledge</div>
                    <div className='content'>Kenverse is a suite of products that are designed to help organizations bolster and sustain their impact, by enabling  them operate and scale efficiently through technology.</div>
                </div> :
                <div style={{ backgroundImage: `url(${bgDots})` }} >
                    <Grid container spacing={6} className='mx-2 ' id='brands' >
                        <Grid item>
                            <div>
                                <div style={{ marginTop: '10rem' }}>
                                    <div className='d-flex align-items-end'>
                                        <h1 className='Title1 pb-3'>Into the</h1>
                                        <Box component={'div'} sx={{ marginLeft: '24%' }}>
                                            <img src={Circle} alt='circle' opacity={'1'} style={{ zIndex: '99' }} className='circle' />
                                        </Box>
                                    </div>
                                    <div className='hstack gap-3'>
                                        <h1 className='Title2 pt-4'>Kenverse</h1>
                                        <div className='hstack gap-3 m-3'>
                                            <div className='Hline' />
                                            <div className='font-black-22-thin'>Building a universe of knowledge</div>
                                        </div>
                                    </div>
                                    <div className='font-black-30-normal w-70 mt-4 ' style={{ lineHeight: '38px' }}>Kenverse is a suite of products that are designed to help organizations bolster and sustain their impact, by enabling them operate and scale efficiently through technology.</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }

        </>
    )
}

export default Index